import React from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const UsersDataTable = ({ columns, data }) => {
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set the initial page index to 0 and page size to 20
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, globalFilter } = state;

  const handleRowClick = (userId) => {
    // Navigate to the user page with the userId
    navigate(`/dashboard/user/${userId}`);
  };

  console.log("columns", columns);
  console.log("data", data);

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Get the current page URL path
    const path = window.location.pathname;
    // Manipulate the path to get the desired filename format
    const fileName = path
      .split("/")
      .slice(2) // Remove the base domain and leading '/'
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
      .join(" > ") // Replace '/' with '>'
      .replace(/\//g, " - ") // Replace '/' with ' - '
      .replace(/ /g, ""); // Remove spaces
    // Append a timestamp to the filename
    const timestamp = new Date()
      .toISOString()
      .replace(/:/g, "-")
      .replace("T", " ")
      .split(".")[0];
    const finalFileName = `${fileName} ${timestamp}${fileExtension}`;

    const formattedData = data?.map(({ userId, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: fileType });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = finalFileName;
    downloadLink.click();
  };

  return (
    <div>
      <Row>
        <Col md={9}>
          <div>
            <div class="form-floating mb-3">
              <input
                type="text"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search..."
                className="form-control form-control-solid-bordered"
                aria-describedby="..."
                id="floatingInput"
              />
              <label for="floatingInput">Search</label>
            </div>
          </div>
        </Col>
        <Col md={3} className="d-flex align-items-center gap-2">
          <Button className="mb-3" onClick={exportToExcel}>
            Export Excel
          </Button>
        </Col>
      </Row>

      <table
        {...getTableProps()}
        style={{ borderSpacing: "0", width: "100%" }}
        className="table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: "1px solid black",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " ↓"
                        : " ↑"
                      : "↓↑"}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                style={{ cursor: "pointer" }}
                {...row.getRowProps()}
                onClick={() => handleRowClick(row.original.userId)}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ padding: "10px", borderBottom: "1px solid black" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <Row className="mt-2">
          <Col xs={6} className="d-flex align-items-center">
            <span className="m-2">
              Page <strong>{pageIndex + 1}</strong>{" "}
            </span>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <button
              className={`btn ${
                canPreviousPage ? "btn-primary" : "btn-light"
              } m-1`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous Page
            </button>
            <button
              className={`btn ${canNextPage ? "btn-primary" : "btn-light"} m-1`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next Page
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UsersDataTable;
