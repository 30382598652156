import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendSMS,
  getBookingByID,
  getCurrentUserDetails,
  getListingByBookingId,
  getUserByID,
  getWaiverDataByName,
  sendEmail,
  tripCompletedEmailBody,
  tripStatusUpdateEmailBody,
} from "../utils";
import {
  get,
  getDatabase,
  push,
  ref,
  serverTimestamp,
  set,
  update,
} from "firebase/database";
import SignatureCanvas from "react-signature-canvas";

export default function EndTrip() {
  const { dynamicId } = useParams();
  const navigate = useNavigate();
  const [formResponses, setFormResponses] = useState([]);
  const [waiverQuestions, setWaiverQuestions] = useState([]);
  const [userData, setUserData] = useState([]);
  const signatureRef = React.useRef();

  const handleImageUpload = async (question, files) => {
    const formData = new FormData();

    console.log("image upload");

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/upload-images`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        const filenames = data.file_names;

        const existingResponseIndex = formResponses.findIndex(
          (response) => response.Question === question
        );

        if (existingResponseIndex !== -1) {
          const updatedResponses = [...formResponses];
          updatedResponses[existingResponseIndex].Answer =
            question === "Picture" ? filenames[0] : filenames;
          setFormResponses(updatedResponses);
        } else {
          setFormResponses([
            ...formResponses,
            {
              Question: question,
              Answer: question === "Picture" ? filenames[0] : filenames,
            },
          ]);
        }
      } else {
        console.error("Error uploading images:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleSaveSignature = () => {
    if (signatureRef.current.isEmpty()) {
      console.error("Signature is empty");
      return;
    }

    const signatureImage = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    const signatureFile = dataURLtoFile(signatureImage, "signature.png");
    handleImageUpload("Signature", [signatureFile]);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  // Initialize an array to track checked questions
  const checkedQuestions = [];

  const handleInputChange = (question, answer) => {
    if (question === "date" || question === "country") {
      // Handle date and country inputs
      const updatedResponses = [...formResponses];
      const existingResponseIndex = updatedResponses.findIndex(
        (response) => response.Question === question
      );

      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].Answer = answer;
      } else {
        updatedResponses.push({ Question: question, Answer: answer });
      }

      setFormResponses(updatedResponses);
    } else {
      const existingResponseIndex = formResponses.findIndex(
        (response) => response.Question === question
      );

      if (existingResponseIndex !== -1) {
        const updatedResponses = [...formResponses];
        updatedResponses[existingResponseIndex].Answer = answer;
        setFormResponses(updatedResponses);
      } else {
        setFormResponses([
          ...formResponses,
          { Question: question, Answer: answer },
        ]);
      }

      if (
        answer === "Checked" ||
        answer === `There's an issue` ||
        answer === `Yes`
      ) {
        if (!checkedQuestions.includes(question)) {
          const newImageQuestion = {
            question: "Picture",
            type: "images",
            id: question + 1,
          };
          const updatedWaiverQuestions = [
            ...waiverQuestions.slice(0, question + 1),
            newImageQuestion,
            ...waiverQuestions.slice(question + 1),
          ];
          const shiftedWaiverQuestions = updatedWaiverQuestions.map(
            (item, index) => ({ ...item, id: index })
          );

          setWaiverQuestions(shiftedWaiverQuestions);
          checkedQuestions.push(question);
          console.log(checkedQuestions);
        }
      } else {
        // Remove the image question if the option is unchecked
        const questionIndex = question;

        console.log("cancelled");
        if (
          questionIndex !== -1 &&
          questionIndex < waiverQuestions.length - 1
        ) {
          const nextQuestion = waiverQuestions[questionIndex + 1].question;
          console.log("nextQuestion");
          console.log(nextQuestion);

          // Check if the next question is not "Please take a picture of it"
          if (nextQuestion == "Picture") {
            // Remove the image question by updating waiverQuestions
            const updatedWaiverQuestions = waiverQuestions.filter(
              (item, index) => index !== questionIndex + 1
            );
            const shiftedWaiverQuestions = updatedWaiverQuestions.map(
              (item, index) => ({ ...item, id: index })
            );
            setWaiverQuestions(shiftedWaiverQuestions);
          }
        }
      }
    }
  };

  const saveSignature = () => {
    if (signatureRef.current.isEmpty()) {
      console.error("Signature is empty");
      return;
    }

    const signatureImage = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    handleImageUpload("Signature", [
      dataURLtoFile(signatureImage, "signature.png"),
    ]);
  };

  const allGood = async (event) => {
    event.preventDefault();

    try {
      // await handleSaveSignature();

      // Define the path in the Firebase database
      const database = getDatabase();
      const tripDataRef = ref(
        database,
        `bookings/${dynamicId}/tripData/waivers/${userData.role} Equipment Checklist`
      );

      // Create an array to store the question-answer pairs
      const questionsArray = waiverQuestions.map((response) => {
        let answer;
        if (response.type === "signature") {
          answer = response.Answer[0];
        } else if (response.type === "images") {
          // For images, use an array of URLs
          answer = "";
        } else {
          // For other types, use the answer directly
          answer = "All Good";
        }

        if (response.question == "Motor Hour Gauge") {
          answer = "";
        }

        return {
          question: response.question,
          answer,
        };
      });

      // Save the array of question-answer pairs to Firebase under the 'questions' field
      await push(tripDataRef, {
        waiverName: "Boat Owner Equipment Checklist",
        questions: questionsArray,
      });

      const bookingRef = ref(database, `bookings/${dynamicId}`);
      await update(bookingRef, { status: "Trip Completed" });

      const tripData = ref(database, `bookings/${dynamicId}/tripData`);
      await update(tripData, { endTimestamp: serverTimestamp() });

      console.log("Form responses saved to Firebase:", questionsArray);

      const userName = userData.firstName + " " + userData.lastName;
      const bookingData = await getBookingByID(dynamicId);
      const bookingTitle = bookingData.listingTitle;
      const subject = "Congratulations! Your trip is now completed";
      const userEmail = userData.email;

      const emailBody = await tripStatusUpdateEmailBody(
        userName,
        bookingData.listingTitle,
        "Trip Completed"
      );
      sendEmail(userEmail, subject, emailBody);

      const smsBody = `Trip of ${bookingData.listingTitle} with renter ${userName} has now complete.`;
      const smsResponse = sendSMS(userData.phone, smsBody);
      console.log(smsResponse);

      const renterData = await getUserByID(bookingData.renter);

      const renterName = renterData.firstName + " " + renterData.lastName;
      const renterEmail = renterData.email;

      const emailRenterBody = await tripCompletedEmailBody(
        renterName,
        bookingTitle,
        bookingData.listingId,
        dynamicId
      );
      sendEmail("renterEmail", subject, emailRenterBody);

      const renterSmsBody = `Your Trip ${bookingTitle} is now marked as complete.`;
      const renterSmsResponse = sendSMS(userData.phone, renterSmsBody);
      console.log(renterSmsResponse);

      navigate(`/dashboard/event/${dynamicId}`);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // await handleSaveSignature();

      // Define the path in the Firebase database
      const database = getDatabase();
      const tripDataRef = ref(
        database,
        `bookings/${dynamicId}/tripData/waivers/${userData.role} Equipment Checklist`
      );

      // Create an array to store the question-answer pairs
      const questionsArray = formResponses.map((response) => {
        let answer;
        if (response.type === "signature") {
          answer = response.Answer[0];
        } else if (response.type === "images") {
          // For images, use an array of URLs
          answer = response.Answer.map((image) => ({ url: image }));
        } else {
          // For other types, use the answer directly
          answer = response.Answer;
        }

        return {
          question:
            waiverQuestions.find((q) => q.id === response.Question)?.question ||
            response.Question,
          answer,
        };
      });

      // Save the array of question-answer pairs to Firebase under the 'questions' field
      await push(tripDataRef, {
        waiverName: "Boat Owner Equipment Checklist",
        questions: questionsArray,
      });

      const bookingRef = ref(database, `bookings/${dynamicId}`);
      await update(bookingRef, { status: "Trip Completed" });

      const tripData = ref(database, `bookings/${dynamicId}/tripData`);
      await update(tripData, { endTimestamp: serverTimestamp() });

      console.log("Form responses saved to Firebase:", questionsArray);

      const userName = userData.firstName + " " + userData.lastName;
      const bookingData = await getBookingByID(dynamicId);
      const bookingTitle = bookingData.listingTitle;
      const subject = "Congratulations! Your trip is now completed";
      const userEmail = userData.email;

      const emailBody = await tripStatusUpdateEmailBody(
        userName,
        bookingData.listingTitle,
        "Trip Completed"
      );
      sendEmail(userEmail, subject, emailBody);

      const renterData = await getUserByID(bookingData.renter);

      const renterName = renterData.firstName + " " + renterData.lastName;
      const renterEmail = renterData.email;

      const emailRenterBody = await tripCompletedEmailBody(
        renterName,
        bookingTitle,
        bookingData.listingId,
        dynamicId
      );
      sendEmail("renterEmail", subject, emailRenterBody);

      navigate(`/dashboard/event/${dynamicId}`);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const fetchWaiverData = async () => {
      const waiverName = "Rental Return Multi Point Inspection";
      const questions = await getWaiverDataByName(waiverName);
      const questionsWithId = questions.map((question, index) => ({
        ...question,
        id: index,
      }));
      const currentUserDetails = await getCurrentUserDetails();

      setWaiverQuestions(questionsWithId);
      setUserData(currentUserDetails);
    };

    fetchWaiverData();
  }, []);

  console.log(waiverQuestions);

  return (
    <>
      <Row className="card p-5 d-flex flex-row align-items-center">
        <Col xs={4}>
          <img
            className="img-fluid"
            src="/assets/backend/images/logo.png"
            style={{backgroundColor:"#121212"}}
          ></img>
        </Col>
        <Col xs={8}>
          <h3
            className=""
            style={{ color: "#27313F", fontWeight: "800", textAlign: "center" }}
          >
            BBR BASS BOAT RENTALS, LLC
          </h3>
        </Col>
      </Row>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Rental Return Multi Point Inspection</h1>
          </div>
        </div>
      </div>

      <Row>
        <Col>
          <div className="card p-4">
            <button className="btn btn-primary" onClick={allGood}>
              All Good
            </button>
            <form onSubmit={handleSubmit}>
              {waiverQuestions.map((questionData) => (
                <div key={`${questionData.question}-${questionData.id}`}>
                  <label className="form-label form-label mt-2">
                    {questionData.question}:
                  </label>
                  <br />
                  {questionData.type === "text" ? (
                    <input
                      required={
                        questionData?.required &&
                        questionData?.required == "true"
                          ? true
                          : false
                      }
                      type="text"
                      className="form-control form-control"
                      value={
                        formResponses.find(
                          (response) => response.Question === questionData.id
                        )?.Answer || ""
                      }
                      onChange={(e) =>
                        handleInputChange(questionData.id, e.target.value)
                      }
                    />
                  ) : questionData.type === "images" ? (
                    <input
                      required={
                        questionData?.required &&
                        questionData?.required == "true"
                          ? true
                          : false
                      }
                      type="file"
                      accept="image/*"
                      capture="camera"
                      multiple
                      onChange={(e) =>
                        handleImageUpload(questionData.id, e.target.files)
                      }
                    />
                  ) : questionData.type === "date" ? (
                    <input
                      required={
                        questionData?.required &&
                        questionData?.required == "true"
                          ? true
                          : false
                      }
                      type="date"
                      className="form-control form-control"
                      value={
                        formResponses.find(
                          (response) => response.Question === "date"
                        )?.Answer || ""
                      }
                      onChange={(e) =>
                        handleInputChange("date", e.target.value)
                      }
                    />
                  ) : questionData.type === "country" ? (
                    <select
                      required={
                        questionData?.required &&
                        questionData?.required == "true"
                          ? true
                          : false
                      }
                      className="form-control"
                      name="settingsInputCountry"
                      value={
                        formResponses.find(
                          (response) => response.Question === "country"
                        )?.Answer || ""
                      }
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                    >
                      <option>United States</option>
                      {/* Add other country options if needed */}
                    </select>
                  ) : questionData.type === "signature" ? (
                    <div>
                      <SignatureCanvas
                        ref={signatureRef}
                        required={
                          questionData?.required &&
                          questionData?.required == "true"
                            ? true
                            : false
                        }
                        canvasProps={{
                          width: 500,
                          height: 200,
                          className: "sigCanvas",
                        }}
                      />
                      <button
                        type="button"
                        onClick={() => signatureRef.current.clear()}
                        className="btn btn-primary"
                      >
                        Clear
                      </button>
                    </div>
                  ) : (
                    questionData.options.map((option) => (
                      <div
                        className="btn btn-light m-1 mb-4 form-check"
                        key={option}
                      >
                        <label htmlFor={option} className="form-check-label">
                          {option}
                        </label>
                        <input
                          required={
                            questionData?.required &&
                            questionData?.required == "true"
                              ? true
                              : false
                          }
                          type="radio"
                          name={questionData.id}
                          value={option}
                          id={questionData.id}
                          className="form-check-input"
                          checked={
                            formResponses.find(
                              (response) =>
                                response.Question === questionData.id
                            )?.Answer === option
                          }
                          onChange={() =>
                            handleInputChange(questionData.id, option)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              ))}
              <button type="submit" className="btn btn-primary mt-3">
                Submit
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
}
