import React from "react";
import { getAllUsersDetails, getAllUsersMeta, getUserMetaByID } from "../utils";
import { useState, useEffect } from "react";
import DataTable from "../UsersDataTable";

export default function BoatOwners() {
  const [allUsers, setAllUsers] = useState([]);
  const [data, setData] = useState([]);

  console.log("Daaaa11111", data);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUsers = await getAllUsersDetails();
        // Convert the object into an array of values
        setAllUsers(allUsers);
      } catch (error) {
        console.error("Error fetching Questions:", error);
      }
    };

    fetchUsers();
  }, []);

  const columns = [
    {
      Header: "BBR ID",
      accessor: "bbrId",
    },
    {
      Header: "Full Name",
      accessor: "name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Address Line 1",
      accessor: "address1",
    },
    {
      Header: "Address Line 2",
      accessor: "address2",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State/Province",
      accessor: "state",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Social Security Number",
      accessor: "Social Security Number",
    },
    {
      Header: "Federal ID",
      accessor: "Federal ID",
    },
    {
      Header: "Individual or Business Name",
      accessor: "Individual or Business Name",
    },
    // Add more columns as needed
  ];
  useEffect(() => {
    if (allUsers.length > 0) {
      const linkUsersAndUsersMeta = async () => {
        const linkedData = await Promise.all(
          allUsers.map(async (user) => {
            if (user.role === "Boat Owner" && user.status === "Active") {
              const userMeta = await getUserMetaByID(user?.userId);

              const getKeyValue = (colum) => {
                return userMeta?.userAnswers?.textQuestions?.filter(
                  (q) => q?.question?.toLowerCase() === colum?.toLowerCase()
                )[0]?.answer;
              };
              const fullName = `${user.firstName} ${user.lastName}`;
              return {
                ...user,
                age: userMeta?.age,
                address1: getKeyValue("Address line 1"),
                address2: getKeyValue("Address line 2"),
                city: getKeyValue("city"),
                state: getKeyValue("State/Province"),
                country: getKeyValue("Country"),
                ["Social Security Number"]: getKeyValue(
                  "Social Security Number (XXX-XX-XXXX)"
                ),
                ["Federal ID"]: getKeyValue("Federal ID (EIN) (XX-XXXXXXX)"),
                ["Individual or Business Name"]: getKeyValue(
                  "Individual or Business Name  (as shown on your income tax return)"
                ),
                name: fullName,
              };
            }
            return null;
          })
        );

        // Filter out null values after Promise.all
        return linkedData.filter(Boolean);
      };

      const fetchData = async () => {
        try {
          const result = await linkUsersAndUsersMeta();
          setData(result);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };

      fetchData();
    }
  }, [allUsers]);

  console.log("dataaa3333", data);

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="page-description">
            <h1>All Boat Owners</h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card calendar-container">
            <div class="card-body">
              <DataTable columns={columns} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
