// ProductList.js

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, usePagination } from "react-table";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { getDatabase, ref, update } from "firebase/database";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { getCurrentUserDetails } from "./utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const ProductList = ({ data, columns, getData }) => {
  const pathName = useLocation();
  const [updateListingLoading, setUpdateListingLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedBoat, setSelectedBoat] = useState({});
  const [search, setSearch] = useState("");
  const [currentBoatIndex, setCurrentBoatIndex] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );

  const memoizedColumns = useMemo(() => columns, [columns]);

  const containerStyle = {
    minHeight: "350px",
    borderRadius: "20px",
    padding: "3px",
    backgroundSize: "cover",
    margin: "0px",
    backgroundPosition: "center",
    position: "relative",
  };

  const priceTagStyle = {
    background: "white",
    color: "black",
    padding: "6px 15px",
    borderRadius: "10px",
    width: "max-content",
    marginTop: "10px",
  };

  const boatFeaturesStyle = {
    gap: "20px",
    background: "grey",
    borderRadius: "10px",
    color: "white",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getCurrentUserDetails();
        setCurrentUser(userDetails);
      } catch (error) {
        console.error("Error Loading Data: 404 ", error);
      }
    };
    fetchUserDetails();
  }, []);

  const UpdateListingState = async (id, status) => {
    try {
      setUpdateListingLoading(true);
      const listingsRef = ref(getDatabase(), `listings/${id}`);

      await update(listingsRef, { boatListed: status });
      await getData();
      console.log("Updated.....");
      setUpdateListingLoading(false);
    } catch (error) {
      console.error("Error Updating Boat:", error);
      toast.error("Error Updating Boat");
    } finally {
      setUpdateListingLoading(false);
    }
  };

  const filteredPage = page?.filter((row) =>
    Object.values(row?.values)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
    )
  );

  return (
    <div>
      <div class="form-floating mb-3">
        <input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="form-control form-control-solid-bordered"
          id="floatingInput"
        />
        <label for="floatingInput">Search</label>
      </div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <strong>
            {selectedBoat?.boatListed ? "Disable" : "Enable"} Listing
          </strong>
        </DialogTitle>
        <DialogContent>
          {selectedBoat?.boatListed
            ? `Are you sure you want to disable this boat? It will be removed from the boat listings but can be re-enabled later.`
            : `Are you sure you want to enable this boat? It will appear in the
          boat listings.`}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setSelectedBoat({});
            }}
            className="bg-danger"
          >
            Cancel
          </Button>
          <Button
            onClick={async (e) => {
              e.stopPropagation();
              if (selectedBoat?.boatListed) {
                await UpdateListingState(selectedBoat?.id, false);
              } else {
                await UpdateListingState(selectedBoat?.id, true);
              }
              setOpen(false);
              setSelectedBoat({});
            }}
          >
            {updateListingLoading ? (
              <ThreeDots
                visible={true}
                height="20"
                width="50"
                color="white"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="container">
        <Row>
          {filteredPage?.map((row, rowIndex) => (
            <Col
              key={rowIndex}
              md={4}
              className="mt-4"
              onMouseOver={() => setCurrentBoatIndex(rowIndex)}
              onMouseLeave={() => setCurrentBoatIndex(null)}
            >
              <Link
                to={`/dashboard/listing/${row.values.id}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  className="row productCard p-3"
                  style={{
                    ...containerStyle,
                    filter: !row?.original?.boatListed ? "grayscale(1)" : "",
                    backgroundImage: `url(${
                      row?.values?.backgroundImage ||
                      "https://images.pexels.com/photos/19415811/pexels-photo-19415811/free-photo-of-back-view-of-man-in-trench-coat-and-hat.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    })`,
                  }}
                >
                  {currentBoatIndex === rowIndex
                    ? pathName?.pathname !== "/dashboard/pending-listings" && (
                        <div
                          style={{
                            position: "absolute",
                            top: "24px",
                            right: "5px",
                            zIndex: 99,
                            width: "140px",
                          }}
                          className="d-flex flex-column gap-1"
                        >
                          <Button
                            style={{ border: "none", outline: "none" }}
                            className={`${
                              !row?.original?.boatListed
                                ? updateListingLoading
                                  ? "bg-black"
                                  : "bg-black"
                                : updateListingLoading
                                ? "bg-black"
                                : "bg-danger"
                            } border-none outline-none`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectedBoat(row?.original);
                              setOpen(true);
                            }}
                          >
                            {!row?.original?.boatListed ? "Enable" : "Disable"}
                          </Button>
                        </div>
                      )
                    : ""}

                  <div
                    className="col-12"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p style={priceTagStyle}>{row.values.price}</p>
                    <div className="row">
                      <div className="col-12"></div>
                      <div className="row">
                        <h3 style={{ padding: "0px", color: "white" }}>
                          {row.values.name}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>

      <Row className="mt-4">
        {/* <Col xs={6} className='d-flex align-items-center'>
          <span className='m-2'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {page.length}
            </strong>{' '}
          </span>
        </Col> */}
        <Col xs={12} className="d-flex justify-content-end">
          <button
            className={`btn ${
              canPreviousPage ? "btn-primary" : "btn-light"
            } m-1`}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous Page
          </button>
          <button
            className={`btn ${canNextPage ? "btn-primary" : "btn-light"} m-1`}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next Page
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default ProductList;
