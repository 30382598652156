import React, { useState } from "react";
import { FaCommentDots, FaPaperPlane, FaTimes } from "react-icons/fa";
import { chatWithAgent } from "./utils";
import { ThreeDots } from "react-loader-spinner";

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isBoatLoading, setIsBoatLoading] = useState(false);

  const [messages, setMessages] = useState([
    {
      text: "Hello! How can I help you today? I'm here to assist.",
      isBot: true,
      timestamp: new Date(),
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      setIsBoatLoading(true);
      if (!inputMessage.trim()) {
        return;
      }

      const newMessage = {
        text: inputMessage,
        isBot: false,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, newMessage]);
      const question = inputMessage;
      setInputMessage("");

      const chatMessage = await chatWithAgent(question);

      const newMessageBot = {
        text: chatMessage?.answer,
        isBot: true,
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, newMessageBot]);
      setIsBoatLoading(false);
    } catch (error) {
      console.error("error sending message ):");
    } finally {
      setIsBoatLoading(false);
    }
  };

  return (
    <div
      className="chatbot-wrapper position-fixed p-3 d-flex flex-column align-items-end"
      style={{ zIndex: 999999999, bottom: "15px", right: "25px" }}
    >
      {isOpen && !isMinimized && (
        <div
          className="card shadow-lg"
          style={{ width: "380px", marginBottom: "16px" }}
        >
          {/* Header */}
          <div className="card-header bg-black text-white d-flex justify-content-between align-items-center p-3">
            <div className="d-flex align-items-center">
              <FaCommentDots className="text-white me-2" size={24} />
              <h5 className="mb-0">BBR Chatbot</h5>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen((prev) => !prev);
                  setIsMinimized(false);
                }}
                className="btn btn-sm text-white"
              >
                <FaTimes size={20} />
              </button>
            </div>
          </div>

          {/* Chat Messages */}
          <div
            className="card-body overflow-auto p-3"
            style={{ height: "400px" }}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`d-flex my-3 ${
                  message.isBot
                    ? "justify-content-start"
                    : "justify-content-end"
                }`}
              >
                <div
                  className={`p-2 rounded ${
                    message.isBot ? "bg-light text-dark" : "bg-black text-white"
                  }`}
                  style={{ maxWidth: "70%" }}
                >
                  <p className="mb-1">{message.text}</p>
                  <p className="mb-0 small text-opacity-75">
                    {message.timestamp.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>
            ))}
            {isBoatLoading && (
              <div className={`d-flex my-3 justify-content-start`}>
                <div
                  className={`p-2 rounded bg-light text-dark`}
                  style={{ maxWidth: "70%" }}
                >
                  <p className="mb-1">
                    <ThreeDots color="black" width={50} height={30} />
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Input Area */}
          <form
            onSubmit={handleSendMessage}
            className="card-footer p-3 border-top"
          >
            <div className="input-group">
              <input
                disabled={isBoatLoading}
                type="text"
                value={inputMessage}
                onChange={(e) => {
                  e.stopPropagation();
                  setInputMessage(e.target.value);
                }}
                placeholder="Type your message..."
                className="form-control"
              />
              <button type="submit" className="btn btn-primary">
                <FaPaperPlane size={20} />
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Chat Button */}
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((prev) => !prev);
          setIsMinimized(false);
        }}
        className="btn btn-primary border border-2 rounded-circle p-3 shadow-lg d-flex align-items-center justify-content-center"
      >
        <FaCommentDots className="text-white" size={24} />
      </button>
    </div>
  );
};
export default ChatBot;
