import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FaCheckCircle, FaRegThumbsUp } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";

// Setup the localizer by providing the moment object to the localizer function
const localizer = momentLocalizer(moment);

console.log("localizerlocalizerlocalizer", localizer);

const TwoMonthCalendar = ({ events }) => {
  const [processedEvents, setProcessedEvents] = useState([]);
  console.log(events, "eventseventsevents");
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color, // Use the color property from the event
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "1px solid #ccc",
      display: "block",
      cursor: "pointer",
    };

    return {
      style,
    };
  };
  useEffect(() => {
    if (events) {
      const processedEvents = events?.map((event) => {
        // Create a new date object to avoid modifying the original
        const startDate = moment(event.start).startOf("day");
        let endDate;

        // If overnight is defined and greater than 0, add 1 day to ensure correct display
        if (event.overNight && parseInt(event.overNight) > 0) {
          // Set end date to be exactly the right number of days
          // This is startDate + overnight days
          endDate = moment(startDate)
            .add(parseInt(event.overNight), "days")
            .endOf("day");
        } else {
          // For non-overnight events, just use the end date as is
          endDate = moment(event.end).endOf("day");
        }

        return {
          ...event,
          start: startDate.toDate(),
          end: endDate.toDate(),
          moreCount: undefined,
          moreCountZ: undefined,
        };
      });

      setProcessedEvents(processedEvents);
    }
  }, [events]);
  console.log(events, "events");
  return (
    <>
      <Row className="single-listing-calendar">
        <Col>
          <Calendar
            localizer={localizer}
            events={processedEvents}
            views={["month"]}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            eventPropGetter={eventStyleGetter}
            style={{ height: "500px" }}
            components={{
              event: ({ event }) => (
                <div
                  style={{
                    backgroundColor: event.color,
                    padding: "5px",
                    borderRadius: "4px",
                    color: "#fff",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {event.title}
                </div>
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default TwoMonthCalendar;
