import React, { useEffect, useState } from "react";
import Listing from "../Listing";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getActiveListings,
  getAllListings,
  getCurrentUserDetails,
  getListingByUserID,
} from "../utils";
import { useAuth } from "../../contexts/AuthContext";

import { useParams } from "react-router-dom";

export default function MyListings() {
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [data, setData] = useState([
    {
      id: 1,
      name: "Product A",
      price: "$100/Hour",
      backgroundImage:
        `${process.env.REACT_APP_BASE_URL}/assets/uploads/sites/153/2023/03/yacht-on-the-move-2022-11-02-16-33-38-utc.jpg`,
    },
    {
      id: 2,
      name: "Product B",
      price: "$120/Day",
      backgroundImage:
        "https://images.pexels.com/photos/6785289/pexels-photo-6785289.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 3,
      name: "Product C",
      price: "$200/Month",
      backgroundImage:
        "https://images.pexels.com/photos/5668278/pexels-photo-5668278.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // ... more data items
  ]);

  const [listings, setListings] = useState([]);

  // Sample columns
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Price", accessor: "price" },
    { Header: "backgroundImage", accessor: "backgroundImage" },
    // ... more columns
  ];

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await getCurrentUserDetails();
        setCurrentUser(user);
      } catch (error) {
        console.error("Error fetching current user:", error);
      }
    };

    fetchCurrentUser();
  }, []);

  const fetchListings = async () => {
    try {
      const listings = await getListingByUserID(auth.currentUser.uid);
      setListings(listings);
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  };
  const fetchAllListings = async () => {
    try {
      const listings = await getActiveListings();
      setListings(listings);
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  };

  const getData = async () => {
    if (currentUser?.role?.toLowerCase() === "Admin"?.toLowerCase()) {
      await fetchAllListings();
    } else {
      await fetchListings();
    }
  };

  useEffect(() => {
    getData();
    return () => {
      setListings([]);
    };
  }, [currentUser?.role]);

  const transformedData = listings
    ? listings.map((listing) => ({
        id: String(listing.id),
        name: listing.listingTitle,
        price: listing.boatStatus,
        backgroundImage: listing.featured_image,
        boatListed: listing?.boatListed,
      }))
    : [];

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="page-description">
            <Row>
              <Col xs={6}>
                <h1>
                  {currentUser?.role?.toLowerCase() === "Admin".toLowerCase()
                    ? "All Listings"
                    : "My Boats"}
                </h1>
              </Col>
              {currentUser?.role?.toLowerCase() !== "Admin".toLowerCase() && (
                <Col
                  xs={6}
                  className="d-flex justify-content-end align-items-center"
                >
                  <Link className="btn btn-primary" to="/dashboard/add-listing">
                    Add Boat
                  </Link>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card calendar-container">
            <div class="card-body">
              {/* Render the Listing component only if there is data */}
              {listings && listings.length > 0 ? (
                <Listing
                  data={transformedData}
                  columns={columns}
                  getData={getData}
                />
              ) : (
                <p>No listings available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
