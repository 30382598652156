import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getAllListings } from "../utils";
import { useNavigate } from "react-router-dom";

const CustomSlickSlider = ({ children }) => {
  const navigate = useNavigate();
  const [allListings, setAllListings] = useState([]);

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: true,
    centerMode: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1, // Ensure 3 slides scroll on larger screens
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchListings = async () => {
      const data = await getAllListings();
      if (data) {
        let featuredBoats = data.filter((boat) => boat.featured);

        const getLocationName = async (dropoff) => {
          try {
            const dropoffLat = dropoff.lat;
            const dropoffLlng = dropoff.lng;

            const dropOffResponse = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${dropoffLat},${dropoffLlng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
            );

            const dropOffdata = await dropOffResponse.json(); //featuredBoats
            const dropoffName =
              dropOffdata.results[0]?.formatted_address || "Location not found";
            return dropoffName;
          } catch (error) {
            console.error("Error fetching location name:", error);
          }
        };

        let finalDataPromises = featuredBoats?.map(async (B) => ({
          ...B,
          Location: await getLocationName(B?.storageAddress),
        }));
        const finalData = await Promise.all(finalDataPromises);

        setAllListings([...finalData, ...finalData]);
      }
    };
    fetchListings();
  }, []);

  const handleListingClick = (id) => {
    navigate(`/listing/${id}`);
  };

  console.log("dataaaaaataataa", allListings);

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {allListings.map((A, i) => {
          return (
            <div key={i} className="w-100">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
                onClick={() => handleListingClick(A?.id)}
                className="cursor-pointer"
              >
                {A?.featured_image ? (
                  <>
                    <img
                      title={A?.description}
                      className="cursor-pointer block rounded-md px-3    "
                      style={{
                        height: "min(300px ,300px)",
                        objectFit: "cover",
                        cursor: "pointer",
                        filter: "brightness(0.7)",
                      }}
                      alt="img"
                      src={`${
                        A?.featured_image ||
                        "https://w7.pngwing.com/pngs/659/927/png-transparent-person-riding-green-kayak-sea-kayak-boating-watercraft-boat-sports-equipment-bow-vehicle-thumbnail.png"
                      }`}
                      // className="w-100 h-100 d-block mx-3"
                    />
                    <div
                      className="p-2 w-100 px-4"
                      style={{ position: "absolute", left: "0%", bottom: "5%" }}
                    >
                      <h3
                        id="landingPageBoatTitle"
                        className="text-white text-bold text-xl"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {A?.listingTitle}
                      </h3>
                      <p
                        className="text-white text-bold"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {A?.Location}
                      </p>
                    </div>
                  </>
                ) : (
                  <p
                    style={{ height: "min(300px ,300px)", cursor: "pointer" }}
                    className="text-center cursor-pointer"
                  >
                    <b>No Image Provided</b>
                    <br />
                    <strong>Boat Name : {A?.boatType}</strong>
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default CustomSlickSlider;
