import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaFacebookF, FaTwitter, FaGoogle, FaInstagram, FaLinkedin,FaYoutube, FaGithub, FaPhoneAlt , FaMapPin, FaRegEnvelope  } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className='bg-light text-center text-lg-start text-muted cs-footer cs-bg-dark'>
      <section className='p-4 border-bottom'>
        <Container className='d-flex justify-content-center justify-content-lg-between '>
          <div className='me-5 d-none d-lg-block'>
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <Link  to="https://www.facebook.com/BBRBassboatrentals/" target="_blank" className='me-4 text-reset'>
              <FaFacebookF color='secondary' />
            </Link>
            <Link to="https://www.instagram.com/bbrbassboatrentals/" target="_blank" className='me-4 text-reset'>
              <FaInstagram color='secondary' />
            </Link>
            <Link to="https://www.youtube.com/channel/UCgOQSUpJj1MkRkt6-tJZJFg" target="_blank" className='me-4 text-reset'>
              <FaYoutube color='secondary' />
            </Link>
            
          </div>
        </Container>
        

      </section>

      <section className='footer-section'>
        <Container className='text-center text-md-start mt-5'>
          <Row className='mt-3'>
            <Col md='3' lg='4' xl='3' sm='12' className='mx-auto mb-4'>
              <img className='mb-2 footer-logo' style={{backgroundColor:"#121212"}} width={'100%'} src="/assets/backend/images/logo.png"/>
            </Col>
            <Col md='2' lg='2' xl='2' sm='6' xs='4' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Navigation</h6>
              <p>
                <Link to='/home' className='text-reset'>
                  Home
                </Link>
              </p>
              <p>
                <Link to='/listings' className='text-reset'>
                  Boats
                </Link>
              </p>
              <p>
                <Link to='/about' className='text-reset'>
                  About
                </Link>
              </p>
              
            </Col>
            <Col md='3' lg='2' xl='2' sm='6' xs='4' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Quick Links</h6>
              <p>
                <Link to='/pricing' className='text-reset'>
                  Pricing
                </Link>
              </p>
              
              <p>
                <Link to='/faq' className='text-reset'>
                  FAQ
                </Link>
              </p>
              <p>
                <Link to='/contact' className='text-reset'>
                  Contact Us
                </Link>
              </p>
            </Col>
            <Col md='3' lg='2' xl='2' xs='4' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>More</h6>
              <p>
                <Link to='/get-verified' className='text-reset'>
                  Get Verified
                </Link>
              </p>
              <p>
                <Link to='/cancellation' className='text-reset'>
                Cancellation
                </Link>
              </p>
              <p>
                <Link to='/launch-load-checklists' className='text-reset'>
                  Launch & Load Checklists
                </Link>
              </p>
            </Col>

          </Row>
          <Row className='footer-contact'>
            <Col md={3} className='d-flex justify-content-start align-items-center'>
              <h5>
                <FaMapPin /> 96 Beach Walk Blvd, Conroe, Texas 77304, United States
              </h5>
            </Col>
            <Col md={3} className='d-flex footer-phone align-items-center'>
              <h5>
                <FaPhoneAlt /> 936-276-7787
              </h5>
            </Col>
            <Col md={3} className='d-flex footer-email align-items-center'>
              <h5>
                <FaRegEnvelope /> info@BBRBoatRentals.com
              </h5>
            </Col>
            <Col md={3} className='d-flex footer-socials align-items-center'>
              <div className="social-icons">
                <Link to="https://www.facebook.com/BBRBassboatrentals/" target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </Link>
                <Link to="https://www.instagram.com/bbrbassboatrentals/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram />
                </Link>
                <Link to="https://www.youtube.com/channel/UCgOQSUpJj1MkRkt6-tJZJFg" target="_blank" rel="noopener noreferrer">
                  <FaYoutube />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 BBR Boat Rentals • All Rights Reserved<br/>
        {/* Powered By <Link className='text-reset fw-bold' target='_blank' to='https://esclatech.com/'>
         Esclatech
        </Link> */}
      </div>
    </footer>
  );
};

export default Footer;
