// Payment.js
import React, { useEffect, useState } from "react";
import DataTable from "../DataTable";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../PaymentForm";
import { getOfferDetailsByBookingId } from "../utils";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function BookingPayment() {
  const [storedPrice, setStoredPrice] = useState(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const bookingId = queryParameters.get("bookingId");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const offerDetails = await getOfferDetailsByBookingId(bookingId);

        const amountFinal = parseInt(offerDetails["offerPrice"] + "00");
        setStoredPrice(amountFinal);
      } catch (error) {
        console.error("Error fetching listing details:", error);
      }
    };
    fetchDetails();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="mt-4">
            <Elements stripe={stripePromise}>
              <PaymentForm amount={storedPrice} />
            </Elements>
          </div>
        </div>
      </div>
    </>
  );
}
