import React from "react";
import MyListings from "../backend/MyListings";

const AllListings = () => {
  return (
    <div className="w-100 h-100 bg-white">
      <MyListings />
    </div>
  );
};

export default AllListings;
