import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getEventsByUserId,
  getEventDetailsByEventID,
  getCurrentUserDetails,
  getAllEvents,
} from "../utils";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

const localizer = momentLocalizer(moment);
export default function Bookings() {
  const auth = useAuth();
  const currentUser = auth.currentUser;

  const Navigate = useNavigate();

  const [selectedEventDetails, setSelectedEventDetails] = useState([]);

  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const userDetails = await getCurrentUserDetails();

        if (
          userDetails.role === "Boat Owner" ||
          userDetails.role === "Renter"
        ) {
          const individualEvents = await getEventsByUserId(currentUser.uid);

          const filteredEvents = individualEvents.map((event) => {
            if (event?.overNight && parseInt(event?.overNight) > 0) {
              const timezone = moment.tz.guess();
              const startMoment = moment(event.start).tz(timezone);
              const endMoment = moment(event.end).tz(timezone);

              if (timezone.startsWith("America/")) {
                startMoment.add(1, "days");
                endMoment.add(1, "days");
              }
              if (event?.inquireInfo?.offerData === undefined) {
                endMoment.add(1, "days");
              }
              return {
                ...event,
                start: startMoment.toDate(),
                end: endMoment.toDate(),
              };
            }

            if (
              event?.inquireInfo?.offerData &&
              parseInt(event?.inquireInfo?.offerData?.offeredHours) > 0
            ) {
              const startDate = new Date(event.start);
              const endDate = new Date(event.end);

              endDate.setDate(endDate.getDate() + 1);

              return {
                ...event,
                start: startDate,
                end: endDate,
              };
            }

            // Return the event unmodified if no overnight stays
            return {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
            };
          });

          setCalendarEvents(filteredEvents);
        } else if (userDetails.role === "Admin") {
          const individualEvents = await getAllEvents(currentUser.uid);

          const filteredEvents = individualEvents.map((event) => {
            if (event.overNight && parseInt(event.overNight) > 0) {
              const startDate = new Date(event.start);
              const endDate = new Date(event.end);

              endDate.setDate(endDate.getDate() + 1);

              return {
                ...event,
                start: startDate,
                end: endDate,
              };
            }

            if (
              event?.inquireInfo?.offerData &&
              parseInt(event?.inquireInfo?.offerData?.offeredHours) > 0
            ) {
              const startDate = new Date(event.start);
              const endDate = new Date(event.end);

              endDate.setDate(endDate.getDate() + 1);

              return {
                ...event,
                start: startDate,
                end: endDate,
              };
            }

            // Return the event unmodified if no overnight stays
            return {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
            };
          });

          setCalendarEvents(filteredEvents);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        setCalendarEvents([]);
      }
    };

    fetchEvents();
  }, [currentUser]);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "1px solid #ccc",
      display: "block",
      cursor: "pointer",
    };

    return {
      style,
    };
  };

  const handleEventClick = (event) => {
    const matchingEvent = calendarEvents.find(
      (calEvent) => calEvent.id === event.id
    );

    const selectedEvent = getEventDetailsByEventID(event.id);

    if (matchingEvent?.inquireInfo !== undefined) {
      setSelectedEventDetails(selectedEvent);
      Navigate(`/dashboard/event/${event.id}`);
    } else {
      toast.error("This inquiry is currently awaiting review.");
    }
  };
  return (
    <>
      <div class="row">
        <div class="col">
          <div class="page-description">
            <h1>Bookings</h1>
          </div>
        </div>
      </div>
      <div class="labels-wrapper gap-[4px] mb-3 align-items-center booking-labels-info">
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "rgb(255 0 0 / 70%)" }}
          >
            Pending
          </h6>
        </div>
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "rgb(101 31 255 / 70%)" }}
          >
            Waivers Pending
          </h6>
        </div>
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "rgb(33 150 243 / 70%)" }}
          >
            Waivers Filled
          </h6>
        </div>
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "rgb(255 165 0 / 70%)" }}
          >
            Trip Started
          </h6>
        </div>
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "rgb(57 87 15 / 70%)" }}
          >
            Trip Returned
          </h6>
        </div>
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "rgb(0 128 0 / 70%)" }}
          >
            Finished
          </h6>
        </div>
        <div class=" p-1">
          <h6
            class="color-box text-center"
            style={{ backgroundColor: "#E843C3" }}
          >
            Trip Cancelled
          </h6>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card calendar-container">
            <div class="card-body cal-body">
              <Calendar
                localizer={localizer}
                views={["month", "week", "day"]}
                events={calendarEvents || []}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                onSelectEvent={handleEventClick}
                popup
                style={{ height: 550 }}
                popupOffset={{ x: 5, y: 5 }}
                showAllEvents={false}
                events_limit={2}
                messages={{
                  showMore: (total) => `+${total} more`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
