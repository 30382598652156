import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import FloatingButtons from "./FloatingButtons";

export default function Pricings() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FloatingButtons />
      <section className="cs-bg-dark py-4 cs-section">
        <div className="container text-white">
          <Row>
            <Col md={12}>
              <h1 className="fw-bold">
                Pricing is set by the individual boat owner upon booking
                inquiry. Click on the boat you are interested in renting under
                the booking page for hourly and daily pricing.
              </h1>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} xs={12}>
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>Hourly rental</h4>
                  <p className="m-0">
                    Take it with you by adding the trailer package
                  </p>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$100</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>8 Hours</h4>
                  <p className="m-0">
                    Take it with you by adding the trailer package
                  </p>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$400</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>1 Day (24 hours)</h4>
                  <p className="m-0">
                    * All overnight rentals require the trailer package
                  </p>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$400</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>2 Days (48 hours)</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$600</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>3 Days (72 hours)</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$700</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>4 Days (96 hours)</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$800</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>5 Days (120 hours)</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$900</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>6 Days (144 hours)</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$1,000</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>Trailer package required for overnight rentals</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$100</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>Boat Delivery and collection</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">Call for pricing</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>Fishing Guide / Boat driver</h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">Call for pricing</h2>
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <Col xs={8} className="d-flex flex-column">
                  <h4>
                    MOTOR HOURS (rental includes 1 hour per day, additional
                    hours are extra)
                  </h4>
                </Col>
                <Col
                  xs={4}
                  className="d-flex justify-content-end align-items-center"
                >
                  <h2 className="m-0">$50</h2>
                </Col>
              </Row>
              <hr />
            </Col>
          </Row> */}
        </div>
      </section>
    </>
  );
}
