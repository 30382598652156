import React, { useEffect, useState } from "react";
import Listing from "../Listing";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getAllListings,
  getAllPendingListings,
  getListingByUserID,
} from "../utils";
import { useAuth } from "../../contexts/AuthContext";

import { useParams } from "react-router-dom";

export default function PendingListings() {
  const auth = useAuth();
  const [data, setData] = useState([
    {
      id: 1,
      name: "Product A",
      price: "$100/Hour",
      backgroundImage:
        "https://www.bbrbassboatrentals.com/assets/uploads/sites/153/2023/03/yacht-on-the-move-2022-11-02-16-33-38-utc.jpg",
    },
    {
      id: 2,
      name: "Product B",
      price: "$120/Day",
      backgroundImage:
        "https://images.pexels.com/photos/6785289/pexels-photo-6785289.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 3,
      name: "Product C",
      price: "$200/Month",
      backgroundImage:
        "https://images.pexels.com/photos/5668278/pexels-photo-5668278.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    // ... more data items
  ]);

  const [listings, setListings] = useState([]);

  // Sample columns
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Price", accessor: "price" },
    { Header: "backgroundImage", accessor: "backgroundImage" },
    // ... more columns
  ];

  //   const [listingDetails, setListingDetails] = useState(null);

  //   const { id } = useParams();
  useEffect(() => {
    const fetchListings = async () => {
      try {
        const listings = await getAllPendingListings();

        setListings(listings);
      } catch (error) {
        // Handle error appropriately, e.g., log it or show an error message
        console.error("Error fetching listings:", error);
      }
    };

    // Call the fetchListings function to trigger the API request
    fetchListings();
    // fetchListingDetails();
  }, []); // Empty dependency array means the effect runs once when the component mounts

  const transformedData = listings
    ? listings.map((listing) => ({
        id: String(listing.id),
        name: listing.listingTitle, // Replace 'listingTitle' with the actual property name in your listing object
        price: listing.boatStatus, // Hardcoded price
        backgroundImage: listing.featured_image, // Assuming 'featured_image' is the property containing the image URL
      }))
    : [];

  {
    console.log("AUTH>USERTYPEEEEEEE", auth.currentUser);
  }

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="page-description">
            <Row>
              <Col xs={6}>
                <h1>Pending Listings</h1>
              </Col>
              <Col
                xs={6}
                className="d-flex justify-content-end align-items-center"
              >
                <Link className="btn btn-primary" to="/dashboard/add-listing">
                  Add Boat
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card calendar-container">
            <div class="card-body">
              {/* Render the Listing component only if there is data */}
              {listings && listings.length > 0 ? (
                <Listing data={transformedData} columns={columns} />
              ) : (
                <p>No listings available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
