import { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FloatingButtons from "./FloatingButtons";
import { useAuth } from "../../contexts/AuthContext";
import { getCurrentUserDetails } from "../../Components/utils";

export default function GetVerified() {
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Navigate = useNavigate();

  const fetchReviewDetails = async () => {
    try {
      const userData = await getCurrentUserDetails();
      setUserDetails(userData);
    } catch (error) {
      console.error("Error fetching listing details:", error);
    }
  };
  useEffect(() => {
    fetchReviewDetails();
  }, []);

  console.log(currentUser, "currentUser");
  console.log(userDetails, "userDetails");
  return (
    <>
      <FloatingButtons />
      <section className="cs-bg-dark py-4 cs-section">
        <div className="container">
          <Row className="mb-4">
            <Col>
              <Row className="mt-4">
                <Col>
                  <h1 className="text-white mb-0">
                    BBR RENTER VERIFICATION PROCESS
                  </h1>
                  <p className="text-white mb-2">
                    *Verification only required for Bass Boats
                  </p>
                  <h4 className="text-white ">
                    Only required for the driver of the boat
                  </h4>
                  <p className="text-white pb-3">
                    The bass boats we rent have 250hp outboard motors, capable
                    of speeds in excess of 70 miles per hour.
                    <br />
                    Our insurance underwriter and common sense requires that we
                    verify our customers ability to safely operate the
                    equipment.
                    <br />
                    If you plan to rent one of our bass boats you will need to
                    get verified and provide certain specific information.
                    <br />
                    If you plan to rent soon we recommend you get verified now!
                    <br />
                    Basically if you are a fisherman and you have experience
                    operating a 20ft bass boat, you will likely have no problem
                    renting a boat from us.
                    <br />
                    Once you are verified, you may rent a boat from BBR bass
                    boat rentals whenever there is a boat available.
                    <br />
                    But don't stress ... Even if you have no experience
                    operating these boats, we can definitely get you set up with
                    a qualified boat captain and fishing guide, just drop us a
                    line 936-276-7787
                  </p>
                  {!currentUser &&
                  userDetails?.status?.toLowerCase() !== "unset" ? (
                    <Link to="/auth/signup-renter" className="btn btn-light">
                      Get Verified
                    </Link>
                  ) : (
                    <Button
                      className="btn btn-light"
                      onClick={() => {
                        if (
                          userDetails?.status?.toLowerCase() ===
                          "unset".toLowerCase()
                        ) {
                          Navigate("/dashboard/get-verified");
                        } else {
                          Navigate("/dashboard/inbox");
                        }
                      }}
                    >
                      Get Verified
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
