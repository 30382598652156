import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getListingByID } from "./utils";
import SingleListingLayout from "./SingleListingLayout";
import FloatingButtons from "./frontend/FloatingButtons";

export default function SingleListing() {
  const [listing, setListing] = useState([]);

  const { dynamicId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchListingDetails = async () => {
      const listingID = String(dynamicId);
      try {
        const details = await getListingByID(listingID);

        setListing(details);
      } catch (error) {
        console.error("Error fetching listing details:", error);
      }
    };
    fetchListingDetails();
  }, []);

  return (
    <>
      <SingleListingLayout initialData={listing} />
    </>
  );
}
