import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom"; // Import NavLink
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";

import { getCurrentUserDetails } from "../utils";

export default function LeftMenu({ onSidebarToggle }) {
  const Navigate = useNavigate();
  const { logout } = useAuth();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await getCurrentUserDetails();
        setCurrentUser(user);
      } catch (error) {
        console.error("Error fetching current user:", error);
      }
    };

    fetchCurrentUser();
  }, []);

  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      console.log("Logged Out");
      Navigate("/");
    } catch {
      console.log("Failed to log out");
    }
  };

  const openSidebar = () => {
    console.log("Clicked");
    if (isMobileDevice()) {
      onSidebarToggle();
    }
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the threshold as needed
  };

  return (
    <>
      {currentUser && (
        <>
          <div className="d-flex justify-content-end mobile-only">
            <Link
              class="nav-link hide-sidebar-toggle-button"
              onClick={openSidebar}
            >
              <i class="material-icons">close</i>
            </Link>
          </div>
          <ul className="accordion-menu">
            <li className="sidebar-title">Apps</li>
            <li onClick={openSidebar}>
              <Link
                to="/dashboard"
                exact
                className={location.pathname === "/dashboard" ? "active" : ""}
              >
                <i className="material-icons-two-tone">dashboard</i>{" "}
                <span>Dashboard</span>
              </Link>
            </li>
            <li onClick={openSidebar}>
              <Link
                to="/dashboard/setting"
                exact
                className={
                  location.pathname === "/dashboard/setting" ? "active" : ""
                }
              >
                <i className="material-icons-two-tone">settings</i>
                <span>Settings</span>
              </Link>
            </li>
            <li onClick={openSidebar} className="mobile-only">
              <Link
                to="/dashboard/inbox"
                exact
                className={
                  location.pathname === "/dashboard/inbox" ? "active" : ""
                }
              >
                <i className="material-icons-two-tone">forum</i>
                <span>Inbox</span>
              </Link>
            </li>
            <li onClick={openSidebar} className="mobile-only">
              <Link
                to="/dashboard/payments"
                exact
                className={
                  location.pathname === "/dashboard/payments" ? "active" : ""
                }
              >
                <i className="material-icons-two-tone">local_atm</i>
                <span>Payments</span>
              </Link>
            </li>

            {currentUser.role === "Boat Owner" && (
              <>
                <li className="sidebar-title">Listings</li>
                <li onClick={openSidebar}>
                  <Link to="/dashboard/my-listings">
                    <i className="material-icons-two-tone">directions_boat</i>
                    Boats
                    <i className="material-icons has-sub-menu">
                      keyboard_arrow_right
                    </i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to="/dashboard/my-listings"
                        exact
                        className={
                          location.pathname === "/dashboard/my-listings"
                            ? "active"
                            : ""
                        }
                      >
                        <span>My Boats</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/dashboard/add-listing"
                        exact
                        className={
                          location.pathname === "/dashboard/add-listing"
                            ? "active"
                            : ""
                        }
                      >
                        <span>Add Boat</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li onClick={openSidebar}>
                  <Link
                    to="/dashboard/pro-staff"
                    exact
                    className={
                      location.pathname === "/dashboard/pro-staff"
                        ? "active"
                        : ""
                    }
                  >
                    <i className="material-icons-two-tone">verified</i>
                    <span>Become A Pro Staff</span>
                  </Link>
                </li>
                <li onClick={openSidebar}>
                  <Link
                    to="/dashboard/how"
                    exact
                    className={
                      location.pathname === "/dashboard/how" ? "active" : ""
                    }
                  >
                    <i className="material-icons-two-tone">checklist</i>
                    <span>How It Works</span>
                  </Link>
                </li>
              </>
            )}
            {currentUser.role === "Renter" && (
              <>
                <li onClick={openSidebar}>
                  <Link
                    to="/dashboard/get-verified"
                    exact
                    className={
                      location.pathname === "/dashboard/get-verified"
                        ? "active"
                        : ""
                    }
                  >
                    <i className="material-icons-two-tone">verified</i>
                    <span>Get Verified</span>
                  </Link>
                </li>
                <li className="sidebar-title">Bookings</li>
                <li onClick={openSidebar}>
                  <Link to="/dashboard/bookings">
                    <i className="material-icons-two-tone">calendar_month</i>
                    Bookings
                    <i className="material-icons has-sub-menu">
                      keyboard_arrow_right
                    </i>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to="/dashboard/bookings"
                        exact
                        className={
                          location.pathname === "/dashboard/bookings"
                            ? "active"
                            : ""
                        }
                      >
                        <span>My Bookings</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/listings"
                        exact
                        className={
                          location.pathname === "/listings" ? "active" : ""
                        }
                      >
                        <span>Find Boats</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {currentUser.role !== "Renter" && (
              <li onClick={openSidebar}>
                <Link
                  to="/dashboard/withdrawals"
                  exact
                  className={
                    location.pathname === "/dashboard/withdrawals"
                      ? "active"
                      : ""
                  }
                >
                  <i className="material-icons-two-tone">payments</i>
                  <span>Withdrawals</span>
                </Link>
              </li>
            )}
            {currentUser.role !== "Boat Owner" &&
              currentUser.role !== "Renter" && (
                // Default menu items for other roles
                <>
                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/all-listings"
                      exact
                      className={
                        location.pathname === "/dashboard/all-listings"
                          ? "active"
                          : ""
                      }
                    >
                      <FaClipboardList
                        size={24}
                        style={{
                          marginRight: "13px",
                          filter:
                            "invert(41%) sepia(17%) saturate(674%) hue-rotate(182deg) brightness(95%) contrast(89%)",
                        }}
                      />
                      {/* <i className="material-icons-two-tone">pending_actions</i> */}
                      <span>All Listings</span>
                    </Link>
                  </li>
                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/pending-listings"
                      exact
                      className={
                        location.pathname === "/dashboard/pending-listings"
                          ? "active"
                          : ""
                      }
                    >
                      <i className="material-icons-two-tone">pending_actions</i>
                      <span>Pending Listings</span>
                    </Link>
                  </li>
                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/users"
                      exact
                      className={
                        location.pathname === "/dashboard/users" ? "active" : ""
                      }
                    >
                      <i className="material-icons-two-tone">group</i>
                      <span>All Renters</span>
                    </Link>
                  </li>
                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/pending-renters"
                      exact
                      className={
                        location.pathname === "/dashboard/pending-renters"
                          ? "active"
                          : ""
                      }
                    >
                      <i className="material-icons-two-tone">person_search</i>
                      <span>Pending Renters</span>
                    </Link>
                  </li>

                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/boat-owners"
                      exact
                      className={
                        location.pathname === "/dashboard/boat-owners"
                          ? "active"
                          : ""
                      }
                    >
                      <i className="material-icons-two-tone">
                        directions_boat_filled
                      </i>
                      <span>All Boat Owners</span>
                    </Link>
                  </li>

                  {/* <li onClick={openSidebar}>
                <Link to="/dashboard/pending-boat-owners" exact className={location.pathname === '/dashboard/pending-boat-owners' ? 'active' : ''}>
                  <i className="material-icons-two-tone">work_history</i><span>Pending Boat Owners</span>
                </Link>
              </li> */}

                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/pending-pro-members"
                      exact
                      className={
                        location.pathname === "/dashboard/pending-pro-members"
                          ? "active"
                          : ""
                      }
                    >
                      <i className="material-icons-two-tone">work_history</i>
                      <span>Pro Member Requests</span>
                    </Link>
                  </li>

                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/coupons"
                      exact
                      className={
                        location.pathname === "/dashboard/coupons"
                          ? "active"
                          : ""
                      }
                    >
                      <i className="material-icons-two-tone">discount</i>
                      <span>Coupons</span>
                    </Link>
                  </li>

                  {/* <li>
                <Link to="/dashboard/disputes">
                  <i className="material-icons-two-tone">report</i>Disputes
                </Link>
              </li> */}

                  <li onClick={openSidebar}>
                    <Link
                      to="/dashboard/waivers"
                      exact
                      className={
                        location.pathname === "/dashboard/add-waiver"
                          ? "active"
                          : ""
                      }
                    >
                      <i className="material-icons-two-tone">draw</i>Waivers
                      <i className="material-icons has-sub-menu">
                        keyboard_arrow_right
                      </i>
                    </Link>
                    <ul className="sub-menu">
                      <li onClick={openSidebar}>
                        <Link
                          onClick={openSidebar}
                          to="/dashboard/waivers"
                          exact
                          className={
                            location.pathname === "/dashboard/waivers"
                              ? "active"
                              : ""
                          }
                        >
                          <span>All Waivers</span>
                        </Link>
                      </li>
                      <li onClick={openSidebar}>
                        <Link
                          onClick={openSidebar}
                          to="/dashboard/add-waiver"
                          exact
                          className={
                            location.pathname === "/dashboard/add-waiver"
                              ? "active"
                              : ""
                          }
                        >
                          <span>Add Waiver</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            <li className="mobile-only" onClick={openSidebar}>
              <Link
                to="/"
                exact
                className={location.pathname === "/" ? "active" : ""}
              >
                <i className="material-icons-two-tone">language</i>
                <span>Back to Website</span>
              </Link>
            </li>
            <li>
              <Link onClick={handleLogout}>
                <i className="material-icons-two-tone">logout</i>Logout
              </Link>
            </li>
          </ul>
        </>
      )}
    </>
  );
}
