import { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyListings from "./backend/FrontEndListings";
import { GiSpeedBoat, GiShipWheel } from "react-icons/gi";
import FloatingButtons from "./frontend/FloatingButtons";
import GoogleReviews from "./frontend/GoogleReviews";
import CustomSlickSlider from "./frontend/CustomSlickSlider";

export default function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FloatingButtons />
      {/* <section
        className="heroSection"
        style={{
          backgroundImage:
            "url('https://bbrbassboatrentals.com/assets/backend/images/hero-img-bbr.jpg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        <video className="heroVideo" autoPlay muted loop>
          <source
            src="https://www.bbrbassboatrentals.com/assets/uploads/sites/153/2023/03/hero-section-video.mp4"
            type="video/mp4"
          />
        </video>
      </section> */}

      <section className="my-4">
        <Container>
          <Row>
            <Col xs={12} className=" my-2">
              <h1 className="fw-bold text-white mt-2">Featured Boats</h1>
            </Col>
          </Row>
          <Col xs={12}>
            <CustomSlickSlider />
          </Col>
        </Container>
      </section>

      <section className="cs-bg-dark py-4 cs-section">
        <Container>
          <Row className="flex flex-col">
            <Col md={12} className="p-0 mt-2">
              <h1 className="fw-bold text-white mt-2">Our Fleet</h1>
            </Col>
            <Col md={12} className="p-0 mt-2">
              <p className="text-white">
                At BBR Boat Rentals, we take pride in offering a diverse fleet
                of watercraft to suit every adventure on the water. Whether
                you&#39;re chasing trophy fish, carving through waves, or
                enjoying a relaxing day with family and friends, we have the
                perfect vessel for you. From high-performance 
                <strong>bass boats</strong> designed for tournament-ready
                excellence to <strong>sport fishing vessels</strong> equipped
                for offshore excursions, our fleet is built for every kind of
                water enthusiast. Looking for speed and excitement? Rent 
                <strong>jet skis</strong> or <strong>wakeboarding boats</strong>
                 for an adrenaline- fueled ride. Prefer a laid-back cruise?
                Spacious <strong>pontoons</strong> and 
                <strong>leisure craft</strong> provide the perfect setting for
                relaxation. For those who love to explore, you can rent 
                <strong>kayaks</strong> that let you navigate scenic waterways
                up close. <br />
                <br />
                With a peer to peer network across the nation, BBR Boat Rentals
                ensures you have access to top-quality watercraft, no matter
                where your adventures take you.
              </p>
              <Link to="/listings" className="btn btn-light btn-outline-light">
                Learn More
              </Link>
            </Col>
          </Row>
          <Row className="p-0">
            <MyListings />
          </Row>
        </Container>
      </section>

      <section className="cs-bg-dark py-4 cs-section home-about">
        <Container>
          <Row className="mb-3">
            <Col className="p-0">
              <img width={160} src="/assets/backend/images/logo.png" style={{backgroundColor:"#121212"}}/>
              <h1 className="fw-bold text-white mt-3">
                HOME OF THE TOURNAMENT-READY BASS BOAT!
              </h1>
              <p className="text-white">
                Looking to rent a tournament-ready bass boat for your next
                competition or fishing adventure? BBR Boat Rentals has you
                covered with top-tier, high-performance bass boats designed for
                serious anglers.
                <br />
                <br />
                Do you own a tournament-ready bass boat that you’d like to rent
                through the BBR booking platform? We have a database of verified
                bass boat operators ready to book your vessel. Turn your
                depreciating asset into positive cash flow by listing it with us
                today!
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="p-0">
              <h1 className="fw-bold text-white mt-3">
                FIND A FISHING GUIDE OR CHARTER ANYWHERE IN THE U.S.
              </h1>
              <p className="text-white">
                Planning a trip and want to add some world-class fishing to your
                itinerary? Whether you&#39;re heading to the coast for an
                offshore adventure, exploring freshwater lakes, or chasing
                trophy fish in hidden rivers, BBR Boat Rentals connects you with
                the best fishing guides and charters across the nation.
                <br />
                <br />
                Our network of experienced guides ensures you get the ultimate
                fishing experience—whether you&#39;re a seasoned angler or
                casting a line for the first time. From deep-sea sport fishing
                and bass fishing tournaments to laid-back inshore trips, we have
                the perfect guide for your next destination.
                <br />
                <br />
                <Link
                  to="/listings"
                  className="cs-tx-light nav-link p-0 hover:text-red-500 text-link"
                  // onClick={handleNavbarToggle}
                  state={{ preselectedBoatType: "Fishing Guide" }}
                >
                  Simply search by city or region, choose your fishing style,
                  and book with confidence. Your next fishing adventure is just
                  a
                  <strong
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                     click away!
                  </strong>
                </Link>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12} className="p-0 text-left reviews-home">
              <GoogleReviews />
            </Col>
            <Col md={5} className="p-0">
              <Row>
                <Col>
                  <img
                    className="rounded"
                    width={"100%"}
                    src="/assets/frontend/images/bbrboat2.jpg"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="cs-bg-dark py-4 cs-section statsSection">
        <Container>
          <Row>
            <Col md={12} className="statsCol">
              <GiSpeedBoat
                className="text-white mb-4"
                style={{ fontSize: "90px" }}
              />
              <h4 className="text-white fw-bold">
                Tournament Ready Bass Boats
              </h4>
              <p className="text-white">
                Tournament ready bass boats With trailer, available up to 6 days
                so you can trailer to your tournament, pre-fish, win it all and
                return as your schedule allows. Includes all tournament circuit
                features such as live wells and required safety equipment. Check
                with the boat owner upon booking for your specific needs.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="cs-bg-dark py-4 cs-section">
        <Container>
          <Row>
            <Col md={4}>
              <h2 className="fw-bold text-white mt-2">Our Partners</h2>
              <p className="text-white">
                Proud to collaborate with industry-leading partners who share
                our commitment to excellence
              </p>
            </Col>
            <Col md={8}>
              <Row className="partners-brands">
                <Col
                  md={4}
                  className="d-flex align-items-center justify-content-center my-1"
                >
                  <Link to="https://www.texasfishingforum.com/" target="_blank">
                    <img
                      width={"100%"}
                      height={"auto"}
                      src="/assets/frontend/images/partner1.webp"
                    />
                  </Link>
                </Col>
                <Col
                  md={4}
                  className="d-flex align-items-center justify-content-center my-3"
                >
                  <Link to="https://conroebass.com/" target="_blank">
                    <img
                      width={"100%"}
                      height={"auto"}
                      src="/assets/frontend/images/partner3.webp"
                    />
                  </Link>
                </Col>
                <Col
                  md={4}
                  className="d-flex align-items-center justify-content-center my-1"
                >
                  <Link
                    to="https://www.matagordamarineservices.com/"
                    target="_blank"
                  >
                    <img
                      width={"100%"}
                      height={"auto"}
                      src="/assets/frontend/images/martagorda.png"
                    />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
