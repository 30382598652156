import React from 'react'
import { Outlet, Link } from 'react-router-dom'

export default function AuthLayout() {
  return (
    <>
        <div class="app app-auth-sign-up align-content-stretch d-flex flex-wrap justify-content-end">
        <div class="app-auth-background">

        </div>
        <div class="app-auth-container">
            <div class="logo">
                <Link to="/">BBR - Boat Rentals</Link>
            </div>
            <div className='mobile-logo text-center'>
              <img width={'80%'} src="/assets/backend/images/logo.png" style={{backgroundColor:"#121212"}}/>
              <h1>BBR - Boat Rentals</h1>
            </div>
            <Outlet/>
            
        </div>
    </div>
    </>
  )
}
